import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"
import BuildingLogComponent from "../components/buildingLogComponent"

const BuildingLog = () => {
	const AcfQuery = useStaticQuery(
		graphql`
		  query BuildingLogQuery {
			allWordpressPage(filter: {title: {eq: "Dziennik budowy"}}) {
				edges {
					node {
						acf {
							banner_title_first_part
							banner_title_second_part
							banner_image {
								localFile {
									url
								}
							}
							building_log {
								title
								subtitle
								content
								images {
									image {
										localFile {
											url
										}
									}  
								}
								downloads_group_one {
									title
									file {
										localFile {
										  	url
										}
									}
								}
								downloads_group_two {
									title
									file {
									  localFile {
										url
									  }
									}
								  }
							}
						}
					}
				}
			}
		  }
		`
	)

	const data = AcfQuery.allWordpressPage.edges[0].node.acf;


	return (
		<Layout>
			<SEO title="Dziennik budowy" />
			<SmallBanner 
				image={ data.banner_image.localFile.url } 
				// image={ require('../assets/img/BuildingLogBanner.jpg') }
				titleFirstPart={ data.banner_title_first_part  }
				titleSecondPart={ data.banner_title_second_part  }
			/>
			<BuildingLogComponent 
				building_log={ data.building_log }
			/>
		</Layout>
	)
}

export default BuildingLog
