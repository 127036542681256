import React from "react"

const Stage = ({ title, subtitle, content, downloads, images }) => (
	<div class="stage">
        <h2 className="stage__title mb-10">
            { title }
        </h2>
        <p className="stage__text mb-20">
            { subtitle }
        </p>
        <div className="stage__underline mb-30" />
        <div 
            className="stage__content"
            dangerouslySetInnerHTML={{ __html: content }}
        />
        { downloads[0].title &&
            downloads.map((item, index) => (
                <a 
                    className="stage__link"
                    href={ item.file.localFile.url } 
                    key={index}
                >
                    { item.title }
                </a>
            )) 
        }
        <div className="stage__images">
            { images.map((item, index) => {
                    if(item.image) {
                        return(
                            <img src={ item.image.localFile.url } alt="" key={ index } className="images__item"/>
                            // <img src={ require('../assets/img/placeholder.jpg') } alt="" key={ index } className="images__item"/>
                        )
                    } else {
                        return false
                    }
              }) 
            }
        </div>
    </div>
)

export default Stage
