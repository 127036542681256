import React from "react"

import Stage from "./stage"

const BuildingLogComponent = ({ building_log }) => (
	<section className="building-log-component">
        <div className="container">
            <div className="row">
                <div className="col-12 building-log-component__holder">
                    {
                        building_log.map((item, index) => (
                            <Stage 
                                key={ index }
                                title={ item.title }
                                subtitle={ item.subtitle }
                                content={ item.content }
                                downloads={[
                                    item.downloads_group_one,
                                    item.downloads_group_two
                                ]}
                                images={ item.images }
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </section>
)

export default BuildingLogComponent
